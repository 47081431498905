.inline-header {
  background-color: rgba(125, 176, 173, 1);
}
.inline-header-title {
  margin: 0;
  padding: 1rem;
  color: white;
}
.inline-header-title span {
  font-family: "Futura LT";
  font-weight: bold;
}
