.mainpage-container {
  display: flex;
  margin: 0 2.5rem;
}
.mainpage-carousel-title-container {
  position: absolute;
  left: 73.5%;
  top: 35%;
  transform: translate(-50%, -50%);
  width: 560px;
  overflow: hidden;
}

.featured-products-header {
  font-weight: lighter;
  margin: 2em 0;
  color: rgb(125, 176, 173);
  letter-spacing: -0.05rem;
}

.full-screen-image-summary-1 {
  color: white;
}
.page-content-container {
  margin: 3em 0;
}

.customer-card-container {
  background-color: #f0f0f0;
}
.customer-card {
  padding: 4.5em 5rem;
  padding: 9em 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(34, 42, 49);
  font-size: 0.75rem;
}
.customer-card-header {
  margin: 1em 0;
  font-weight: lighter;
}
@media only screen and (min-width: 768px) {
  .featured-products-header {
    font-size: 3rem;
  }
  .customer-card {
    padding: 9em 5rem;
  }
}
