.blog-container {
  display: flex;
}
.instagram-img-container {
  flex-basis: 50%;
  flex-grow: 1;
}
.instagram-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-column-container {
  display: flex;
  flex-wrap: wrap;
}
