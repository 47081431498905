.collection-card__overlay {
  position: absolute;
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease 0s;
}
.collection-card__wrapper {
  position: relative;
}

.collection-card__title {
  color: white;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: flex-end;
  padding-left: 0.5em;
  padding-bottom: 0.5em;
}

.collection-card{
  font-weight: 500;
}