.collection__header {
  padding: 1.5em 0;
}
.collection__description {
  padding-bottom: 2em;
}
.collection__short-description {
  padding-bottom: 3em;
}
.collection__fabrics {
  padding-bottom: 3em;
}
.collection__short-card-container {
  padding: 0 0 3em 0;
}
