.page-container-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 0 5em 0;
}
.page-header,
.page-content {
  display: flex;
  justify-content: center;
}
.page-header {
  padding: 1em 0;
  font-size: 2.5rem;
  color: rgba(125, 176, 173, 1);
}
.page-content {
  padding: 1em 0;
  font-size: 1.5rem;
  color: rgb(112, 112, 112);
}
