.quick-view-container {
  background: rgba(226, 219, 208, 0.95);
  color: rgb(91, 135, 134);
  /* justify-content: center; */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  line-height: 50px;
  letter-spacing: -0.05rem;
}
.product-summary {
  padding: 1.5em 1.5em 1.5rem;
}
.product-image {
  overflow: hidden;
  position: relative;
}
.product-link,
.quick-view-link {
  text-decoration: none;
  cursor: pointer;
}
.quick-view-link {
  width: 100%;
  display: flex;
  justify-content: center;
}
.quick-view-title {
  display: flex;
  color: rgb(91, 135, 134);
  font-size: 14px/20px;
  font-family: "Futura LT";
  font-weight: 600;
}
.product-title {
  color: rgb(91, 135, 134);
  font-weight: 600;
  font-size: 16px/20px;
  font-family: "Futura LT";
}
.product-price {
  font-size: 18px/22px;
  font-family: "Futura LT";
  color: rgb(112, 112, 112);
}

.product-link p {
  margin: 0;
}
