.terms-and-conditions-container {
    text-align: justify;
}

.terms-and-conditions {
    margin: 15px 0px;
}

.terms-and-conditions p,
.terms-and-conditions ul {
    margin: 20px 0px;

}
.terms-and-conditions ul{
    padding-left:30px;
}

.terms-and-conditions ul li strong {
    font-size: 18px;
}