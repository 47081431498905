.footer-container {
  background-color: rgb(125, 176, 173);
  padding: 4em 0;
}
.footer-column-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.footer-column-container-wrapper {
  height: 100%;
  min-width: 165px;
}
.footer-column-container,
.footer-column-title,
.footer-item-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.footer-column-title {
  justify-content: center;
  /* font-size: 13px; */
}
.footer-item-list {
  margin: 0;
  padding: 0;
  justify-content: left;
  display: flex;
  margin-top: 1.5rem;
}
.footer-list-item {
  list-style: none;
  margin-bottom: 15px;
  font-size: 0.9rem;
}
.footer-item-url,
.footer-social-media-url {
  cursor: pointer;
  text-decoration: none;
}

.footer-column-title,
.footer-list-item,
.footer-item-url,
.footer-social-media-url {
  color: white;
}
.footer-social-media-url {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 2em 0 0 0;
}
.footer-contact-mail,
.footer-contact-tel {
  display: flex;
  text-decoration: none;
  color: white;
  cursor: pointer;
  margin-bottom: 15px;
}
.footer-contact-info {
  color: white;
  display: flex;
  flex-direction: column;
}
.footer-contact-info span {
  font-size: 0.8rem;
}

.copy-right-container {
  background-color: #e2dbd0;
  display: flex;
  justify-content: center;
}

.copy-right-text {
  text-align: center;
  font-family: "Futura LT";
  font-size: 15px/1.4rem;
  color: #2b2b2b;
  padding: 15px 0;
}
.flag-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
}
.flag-container > img {
  height: 2rem;
  margin-right: 30px;
}
.footer-list-item .icon-title {
  margin: 1px 0 0 5px;
}
