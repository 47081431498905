.category-card-container {
}
.category-card-image {
  max-width: 100%;
}
.category-card-url {
  text-decoration: none;
  cursor: pointer;
}
.category-card-url:hover .category-card-header {
  color: white;
}
.category-card-url:hover .category-card-overlay {
  opacity: 0.3;
}
.category-card-description {
  opacity: 0;
  visibility: hidden;
  color: white;
  transition:  visibility 0.6s, opacity 0.6s linear;
  text-overflow: ellipsis;
  width: 100%;
  padding: 0px 20px 20px;
  place-self: flex-end;
  place-content: center;
  display: flex;
  max-height: 150px;
  overflow: hidden;
}

.category-card-url:hover .category-card-description {
  visibility: visible;
  opacity: 1;
}

.category-card-section {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.category-card-header {
  color: black;
  transition: color 0.6s ease 0s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  text-align: center;
}
.category-card-overlay {
  position: absolute;
  top: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.6s ease 0s;
}
.category-card-wrapper {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .category-card-header {
    font-size: 1.5rem;
  }
}
