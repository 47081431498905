.return-policy-container {
    text-align: justify;
}

.return-policy {
    margin: 15px 0px;
}

.post-code {
    margin: 10px 0px;
}
.return-policy p,
.return-policy ul {
    margin: 20px 0px;

}
.return-policy ul{
    padding-left:30px;
}

.return-policy ul li strong {
    font-size: 18px;
}