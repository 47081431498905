.zoomableImage .img-responsive {
  max-width: 100%;
  display: block;
  height: auto;
  transition: transform 500ms; /* Animation */
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}
.zoomableImage .img-responsive:hover {
  transform: scale(1.3);
}
.zoomableImage {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
