.services-container {
  text-align: justify;
  margin: 15px 0px 20px;
}
.services-container h2 {
  margin: 20px 0px;
}
.services-container .service-list {
  margin: 20px 0px;
  list-style: none;
}


