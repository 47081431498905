.mobile-header-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 1rem;
}

.fullscreen-image-container {
  position: relative;
  overflow: hidden;
  display: flex;
}
.right-top {
  left: calc(75% - 300px);
  top: 25%;
}

.right-middle {
  left: calc(75% - 300px);
  top: 50%;
}
.right-bottom {
  left: calc(75% - 300px);
  top: 75%;
}

.center-top {
  left: calc(50% - 300px);
  top: 25%;
}

.center-middle {
  left: calc(50%);
  top: 50%;
  transform: translate(-50%, -50%);
}

.center-bottom {
  left: calc(50% - 300px);
  top: 75%;
}

.left-top {
  left: calc(25% - 300px);
  top: 25%;
}

.left-middle {
  left: calc(25% - 300px);
  top: 50%;
}

.left-bottom {
  left: calc(25% - 300px);
  top: 75%;
}
.image-header-container {
  width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  padding: 1.5em 0;
  border-radius: 0.5em;
}

.image-header-container > h1,
.image-header-container > a {
  display: flex;
  justify-content: center;
}
.image-header-container b {
  font-weight: bold;
}
.image-header-container > h1 {
  font-size: 1.2rem;
  font-family: "Futura LT";
  font-weight: normal;
  display: flex;
  justify-content: center;
  font-weight: lighter;
  margin-bottom: 20px;
}

.image-header-container > a {
  text-decoration: none;
}
.image-header-container > a > span {
  transition: color 0.6s ease 0s;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: black;
  color: white;
  padding: 10px 20px;
  outline: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: "Futura LT";
}

.image-header-container > a > span:hover {
  background: transparent;
  border-color: black;
  color: #2b2b2b;
  border-width: 1px;
  border-color: rgb(43, 43, 43);
  border-style: solid;
  padding: 10px 20px;
}

@media only screen and (min-width: 600px) {
  .image-header-container {
    position: absolute;
    width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .image-header-container > h1,
  .image-header-container > a {
    display: flex;
    justify-content: center;
  }
  .image-header-container b {
    font-weight: bold;
  }
  .image-header-container > h1 {
    font-size: 2.5rem;
    font-family: "Futura LT";
    font-weight: normal;
    display: flex;
    justify-content: center;
    font-weight: lighter;
    margin-bottom: 20px;
  }

  .image-header-container > a {
    text-decoration: none;
  }
  .image-header-container > a > span {
    transition: color 0.6s ease 0s;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: black;
    color: white;
    padding: 15px 30px;
    outline: white;
    text-decoration: none;
    font-size: 18px/1.4rem;
    font-family: "Futura LT";
  }

  .image-header-container > a > span:hover {
    background: transparent;
    border-color: black;
    color: #2b2b2b;
    border-width: 1px;
    border-color: rgb(43, 43, 43);
    border-style: solid;
    padding: 15px 30px;
  }
}
@media only screen and (min-width: 768px) {
}

.fullscreen-image__zoom{
  position: fixed;
  z-index: 9999;
  width: 100%;
  overflow: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 5em 5em 5em 5em;
  background: rgb(0,0,0,0.5);
  transition: all 1s ease;
}

.fullscreen-image__zoom .img-responsive{
  max-width: none;
}
