.instagram-image-overlay {
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.6))
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  top: 0;
  /* background-color: black; */
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.instagram-image-container {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
}
.instagram-image-url {
  text-decoration: none;
  color: white;
  display: flex;
  width: 100%;
  align-items: flex-start; /* new */
}
.instagram-image-url:hover .instagram-image-overlay {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.instagram-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.instagram-image-header {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 1rem;
  max-height: 4rem;
  overflow: hidden;
}
.instagram-blog-image {
  /* display: flex;
    margin: 2em 0; */
  /* width: 300px;
  height: 400px;
  float: left; */
  /* width: 100px;
    height: 100px; */
  object-fit: cover;
  display: flex;
  height: 100%;
}
.image-radius {
  border-radius: 1rem;
}
