.page-card-container {
}
.page-card-image {
  max-width: 100%;
}
.page-card-url {
  text-decoration: none;
  cursor: pointer;
}
.page-card-url:hover .page-card-title {
  color: white;
}
.page-card-url:hover .page-card-overlay {
  opacity: 0.3;
}

.page-card-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 800;
  transition: color 0.6s ease 0s;
}
.page-card-overlay {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.6))
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
  top: 0;
  /* background-color: black; */
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  transition: opacity 0.6s ease 0s;
}
.page-card-wrapper {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .page-card-title {
    font-size: 3rem;
  }
}
