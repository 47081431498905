.instagram-blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.clearfix {
  clear: both;
}
.image-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.custom-slider {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 1;
}
.custom-grid-container {
  height: 100%;
}

.custom-grid-container .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding-bottom: 0px;
}
