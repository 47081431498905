.privacy-policy-container {
    text-align: justify;
}

.privacy-policy {
    margin: 15px 0px;
}

.privacy-policy p,
.privacy-policy ul {
    margin: 20px 0px;

}
.privacy-policy ul{
    padding-left:30px;
}

.privacy-policy ul li strong {
    font-size: 18px;
}