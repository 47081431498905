.mobile-navigation-drawer-button {
  float: left;
  text-decoration: none;
  color: initial;
}
.logo-url {
  display: flex;
  justify-content: start;
  align-content: center;
}
.logo {
  width: 100%;
  align-self: flex-start;
}
.navbar-container {
  position: relative;
  z-index: 2;
}
.navbar {
  padding: 0 1rem;
  height: 100px;
  display: flex;
  background: white;
}
.navbar-drawer-menu {
  display: flex;
  flex-direction: column;
  padding: 2em 3rem;
}
.navbar-menu-item {
  list-style: none;
  display: flex;
  margin-right: 0.8rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.navbar-item-url {
  color: initial;
  cursor: pointer;
  text-decoration: none;
  font-family: "Futura LT";
  font-size: 15px/1.4rem;
}

@media only screen and (min-width: 600px) {
  .logo-title {
    font-family: Cinzel;
    font-size: xx-large;
    letter-spacing: 1rem;
    color: rgba(125, 176, 173, 1);
    font-weight: bold;
  }
  .logo-link {
    color: initial;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }

  .navbar-menu > ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
  }

  .navbar-social-media {
    display: flex;
    justify-content: flex-end;
  }
  .navbar-social-media-url {
    margin-left: 1rem;
    color: initial;
    cursor: pointer;
    text-decoration: none;
    font-size: xx-small;
    display: flex;
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }
  .logo {
    align-self: flex-start;
  }
  .logo-url {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .navbar {
    padding: 0 2.5rem;
  }
}
/* @media only screen and (min-width: 768px) {
} */

@media only screen and (min-width: 1200px) {
  .logo-url {
    width: 50%;
  }
}
