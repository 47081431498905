.product-overview {
  padding-bottom: 3em !important;
}
.product-overview__product-container {
  border: 1px solid rgb(229, 229, 229) !important;
}
.product-overview__product-details {
  background-color: rgb(245, 245, 245);
  height: 100%;
  padding: 1.5em 1em;
}
.product-overview__carousel {
  padding: 1.5em;
}
.product-overview__buy button {
  background-color: rgba(125, 176, 173);
}
.product-overview__buy button:hover {
  background-color: rgb(64, 172, 166);
}